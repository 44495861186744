import { onDomReady } from '../../components/dynamic/observer';

class MenuCloser {
  constructor(closer) {
    this.closer = closer;
    this.header = document.querySelector('[data-header]');
    this.hamburger = document.querySelector('[data-menu-button]');
    this.dropMenu = document.querySelector('[data-drop-menu]');

    this.init();
  }

  init() {
    this.addEvents();
  }

  addEvents() {
    this.closer.addEventListener('click', (e) => {
      e.preventDefault();
      this.closeMenu();
    });
  }

  closeMenu() {
    this.hamburger.classList.remove('_open');
    this.dropMenu.classList.remove('_open');
    this.header.classList.remove('_open');
    document.body.style.position = 'relative';
    document.body.style.overflow = '';
    document.body.style.height = '';
  }
}

onDomReady(() => {
  document.querySelectorAll('[data-catalog-menu-closer]').forEach((closer) => {
    new MenuCloser(closer);
  });
});