/**
 * Раскрываем список по 3 штуки при нажатии
 * */

import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  const containers = document.querySelectorAll('[data-popular-container]');

  containers.forEach((container) => {
    if (container) {
      const list = container.querySelector('[data-popular-list]');
      const items = list.querySelectorAll('[data-popular-item]');
      const heights = [];
      let currentIndex = 1;
      const link = container.querySelector('[data-popular-link]');
      let open = true;

      items.forEach((item, index) => {
        heights.push(item.clientHeight);
        if (index > currentIndex) {
          item.style.height = 0;
        }
      });

      link.addEventListener('click', () => {
        if (open) {
          currentIndex += 4;
          items.forEach((item, index) => {
            if (index < currentIndex) {
              item.style.height = `${heights[index]}px`;
            }
          });
          if (currentIndex >= items.length) {
            link.innerHTML = 'Свернуть';
            link.classList.toggle('_active');
            open = false;
          }
        } else {
          currentIndex = 1;
          items.forEach((item, index) => {
            heights.push(item.clientHeight);
            if (index > currentIndex) {
              item.style.height = 0;
            }
          });
          link.innerHTML = 'Показать еще';
          link.classList.toggle('_active');
          open = true;
        }
      });

      window.addEventListener('resize', () => {
        // Передача ресайза контенерам
        items.forEach((item, index) => {
          heights.push(item.clientHeight);
          if (index > currentIndex) {
            item.style.height = 0;
          }
        });
      });
    }
  });
});

// window.addEventListener('resize', () => {
//   // Передача ресайза контенерам
//   instance.containers.forEach(container => container.onResize());
// });