import LiveEvent from '../../components/live/live';
import Modal from '../../components/modal/modal';

new LiveEvent('click', '[data-modal]', function openModal(e) {
  e.preventDefault();
  const link = this;
  const modal = new Modal(this, {
    closerText: '',
    animation: {
      classIn: '_opened',
      classOut: '_closed',
      timeoutIn: 16,
      timeoutOutClass: 16,
      timeoutOut: 816,
    },
    onFormSuccess() {
      const submitEvent = new Event('formSubmit');
      submitEvent.element = link;
      document.dispatchEvent(submitEvent);
    },
    onAfterOpen: function () {
      setTimeout(() => {
        if (this.content.querySelector('[data-modal-form]')) {
          this.content.querySelector('[data-modal-form]').classList.remove('_awaiting-animation');
        } else {
          // animate content
        }
      }, 16);
    },
    onBeforeClose: function () {
    },
    onFormSuccess: function () {
      const submitEvent = new Event('modal-form:success');
      submitEvent.element = link;
      document.dispatchEvent(submitEvent);
      if (this.content.querySelector('[data-modal-success]')) {
        this.content.querySelector('[data-modal-success]').classList.remove('_awaiting-animation');
      } else {
        // animate content
      }
    },
  });
  return false;
});