import {
  Controller, EffectCreative, Navigation, Swiper,
} from 'swiper';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  const container = document.querySelector('[data-production-slider-container]');

  if (container) {
    const imageSliderEl = container.querySelector('[data-image-production-slider]');

    const leftArrow = container.querySelector('[data-production-slider-left]');
    const rightArrow = container.querySelector('[data-production-slider-right]');

    // eslint-disable-next-line no-new
    new Swiper(imageSliderEl, {
      resizeObserver: false,
      modules: [Controller, Navigation, EffectCreative],
      speed: 1500,
      preventInteractionOnTransition: true,
      slidesPerView: 'auto',
      navigation: {
        prevEl: leftArrow,
        nextEl: rightArrow,
      },
      breakpoints: {
        0: {
          spaceBetween: 16,
        },
        767: {
          spaceBetween: 12,
        },
        1199: {
          slidesPerGroupSkip: 1,
          spaceBetween: 22,
        },
      },
    });


    // imageSlider.on('slideChange', () => {
    //   textSlider.slideTo(imageSlider.realIndex, 0);
    // });
  }
});