import { onDomReady } from "../../components/dynamic/observer";

class StagesItem {
  constructor(stagesItem) {
    this.isDesktop = false;
    this.stagesItem = stagesItem;
    this.lineContainer = undefined;
    this.textContainer = undefined;
    this.oneItem = this.stagesItem[0];
    this.lineCount = 16;
    this.itemWidth = 0;
    this.gap = 0;
    this.init();
    this.addEventListener();
  }

  init() {
    this.checkDesktop();
    this.lineContainer = document.querySelector('[data-stages-lines-container]');
    this.textContainer = document.querySelector('[data-stages-text-wrap]');
    this.defineWidth();
    this.gap = this.itemWidth / this.lineCount;
    if (this.isDesktop) {
      this.defineTextContainerHeight();
      this.setTextPosition();
      this.createLines();
    } else {
      this.createMobLines();
    }
  }

  defineWidth() {
    this.itemWidth = this.oneItem.offsetWidth;
  }

  addEventListener() {
    window.addEventListener('resize', () => {
      this.clearLines();
      this.init();
    });

    if (this.isDesktop) {
      this.stagesItem.forEach((item) => {
        item.addEventListener('mouseenter', (e) => {
          this.hoverItem(e.target);
        });

        item.addEventListener('mouseleave', (e) => {
          this.unhoverItem(e.target);
        });
      });
    } else {
      document.addEventListener('changeSlide', (e) => {
        this.setMobileLineHeight(e.detail);
      });
    }
  }

  createLines() {
    for (let i = 0; i < 5; i++) {
      const leftLine = document.createElement('span');
      const rightLine = document.createElement('span');
      leftLine.classList.add('stages-slider__line');
      rightLine.classList.add('stages-slider__line');
      leftLine.style.left = `-${i * this.gap}px`;
      rightLine.style.right = `-${i * this.gap}px`;
      this.lineContainer.appendChild(leftLine);
      this.lineContainer.appendChild(rightLine);
    }

    this.stagesItem.forEach((item, index) => {
      let offset = 0;
      for (let i = 0; i < this.lineCount; i++) {
        offset = index * this.itemWidth;

        const line = document.createElement('span');
        line.classList.add('stages-slider__line');

        if (i === 0) {
          line.classList.add('stages-slider__line_first');
          line.setAttribute('data-stages-first-line', index);
        }

        line.style.left = `${i * this.gap + offset}px`;
        this.lineContainer.appendChild(line);
      }
    });
  }

  clearLines() {
    this.lineContainer.innerHTML = '';
  }

  hoverItem(item) {
    const index = item.getAttribute('data-stages-item');
    const line = document.querySelector(`[data-stages-first-line="${index}"]`);
    const text = document.querySelector(`[data-stages-text="${index}"]`);

    line.style.height = `calc(100% + ${this.getTextElementHeight(index)}px + 60px)`;

    text.classList.add('_active');
  }

  unhoverItem(item) {
    const index = item.getAttribute('data-stages-item');
    const line = document.querySelector(`[data-stages-first-line="${index}"]`);
    const text = document.querySelector(`[data-stages-text="${index}"]`);

    line.style.height = '100%';

    text.classList.remove('_active');
  }

  defineTextContainerHeight() {
    let height = 0;

    document.querySelectorAll('[data-stages-text]').forEach((item) => {
      if (item.clientHeight > height) {
        height = item.clientHeight;
      }
    });

    this.textContainer.style.height = `${height}px`;
  }

  setTextPosition() {
    const stagesText = document.querySelectorAll('[data-stages-text]');
    stagesText.forEach((item, index) => {
      const textElementsHalf = this.stagesItem.length / 2;
      if (index < textElementsHalf) {
        item.style.left = `${index * this.itemWidth + this.gap}px`;
      } else {
        item.style.right = `${(this.stagesItem.length - index) * this.itemWidth + this.gap}px`;
      }
    });
  }

  getTextElementHeight(index) {
    const textElement = document.querySelector(`[data-stages-text="${index}"]`);
    return textElement.clientHeight;
  }

  checkDesktop() {
    this.isDesktop = window.innerWidth > 1199;
  }

  createMobLines() {
    this.gap = 18;
    const containerWidth = this.lineContainer.offsetWidth;
    const lineCount = Math.ceil(containerWidth / this.gap);

    for (let i = 0; i < lineCount; i++) {
      const line = document.createElement('span');
      line.classList.add('stages-slider__line');

      this.checkActiveLine(line, i);

      line.style.left = `${i * this.gap}px`;
      this.lineContainer.appendChild(line);
    }
  }

  checkActiveLine(line, position) {
    const sliderBlockHeight = document.querySelector('[data-stages-slider]').offsetHeight;
    const firstTextSlideHeight = this.getTextElementHeight(0);
    const textContainerOffset = this.getTextContainerOffset();
    const arrowsHeight = this.getArrowsHeight();

    let activePositions = 0;
    if (window.innerWidth > 767 && window.innerWidth < 1200) {
      activePositions = 4;
    }

    if (position === activePositions) {
      line.setAttribute('data-stages-first-line', '');
      line.style.height = `calc(100% + ${sliderBlockHeight}px + ${firstTextSlideHeight}px + ${textContainerOffset}px + ${arrowsHeight}px)`;
      line.style.top = `calc(-1 * (${sliderBlockHeight}px + ${arrowsHeight}px))`;
      line.classList.add('stages-slider__line_first');
    }
  }

  setMobileLineHeight(index) {
    const sliderBlockHeight = document.querySelector('[data-stages-slider]').offsetHeight;
    const line = document.querySelector('[data-stages-first-line]');
    const textContainerOffset = this.getTextContainerOffset();
    const arrowsHeight = this.getArrowsHeight();

    line.style.height = `calc(100% + ${sliderBlockHeight}px + ${this.getTextElementHeight(index)}px + ${textContainerOffset}px + ${arrowsHeight}px)`;
  }

  getArrowsHeight() {
    const arrows = document.querySelector('.stages-slider__arrows-wrap');
    const arrowsPosition = window.getComputedStyle(arrows).position;
    let arrowsHeight = arrows.offsetHeight;

    if (arrowsPosition === 'absolute') {
      arrowsHeight = 0;
    }

    return arrowsHeight;
  }

  getTextContainerOffset() {
    return parseInt(window.getComputedStyle(this.textContainer).marginTop, 10);
  }
}

onDomReady(() => {
  const stagesItem = document.querySelectorAll('[data-stages-item]');
  if (stagesItem.length) {
    new StagesItem(stagesItem);
  }
});