import {
  Autoplay,
  Controller, EffectCreative, Navigation, Pagination, Swiper,
} from 'swiper';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  const containers = document.querySelectorAll('[data-hero-slider-container]');

  containers.forEach((container) => {
    if (container) {
      const imageSliderEl = container.querySelector('[data-hero-image-slider]');
      const counterEl = container.querySelector('[data-hero-index-slider]');

      let imageSlider;

      let total = [];
      if (counterEl) {
        total = counterEl.querySelectorAll('.swiper-slide');
      }

      const leftArrow = container.querySelector('[data-hero-slider-left]');
      const rightArrow = container.querySelector('[data-hero-slider-right]');

      if (imageSliderEl) {
        imageSlider = new Swiper(imageSliderEl, {
          resizeObserver: true,
          modules: [Controller, Navigation, Pagination, EffectCreative],
          speed: 1200,
          preventInteractionOnTransition: true,
          slidesPerView: 1,
          effect: 'creative',
          creativeEffect: {
            prev: {
              translate: ['-20%', 0, -1],
            },
            next: {
              translate: ['100%', 0, 1],
            },
          },
          navigation: {
            prevEl: leftArrow,
            nextEl: rightArrow,
          },
        });
      }

      imageSlider.on('slideChange', () => {
        total.forEach((el) => {
          el.classList.remove('_active');
        });

        total.item(imageSlider.realIndex).classList.add('_active');
      });
    }
  });
});