import { onDomReady } from '../../components/dynamic/observer';

class OpenText {
  constructor(container) {
    this.container = container;
    this.text = document.querySelector('[data-text-container-content]');
    this.opener = document.querySelector('[data-text-container-open]');
    this.hiddenButton();
    this.open();
  }

  open() {
    this.opener.addEventListener('click', () => {
      // this.text.classList.toggle('open');
      // this.opener.classList.toggle('open');

      if (this.text.classList.contains('open')) {
        // Если открыто, закрываем
        this.text.style.maxHeight = '340px';
      } else {
        // Открываем на высоту контента
        this.text.style.maxHeight = `${this.text.scrollHeight}px`;
      }
      this.text.classList.toggle('open');
      this.opener.classList.toggle('open');
    });
  }

  hiddenButton() {
    if (this.text.scrollHeight < 340) {
      this.opener.style.display = 'none';
      this.text.style.paddingBottom = '20px';
    }
  }
}

onDomReady(() => {
  const container = document.querySelectorAll('[data-text-container]');
  if (container.length) {
    new OpenText(container);
  }
});