require('../scss/app.scss');
require('./svg/svg.js');

window.noZensmooth = true;

require('../fonts/HalvarBreitschrift/stylesheet.css');

require('./common/true-vh');
require('./common/goal');
require('./common/js-validation');
require('./common/links');
require('./common/scroll-to');
require('./common/main');
require('./common/popular-list');

require('./common/pagination');
require('./common/respond');
// require('./common/cursor');
require('./common/map');
require('./common/need-animation');
require('./common/webp-polyfill');
require('./common/follower');
require('./common/hoverListiner');
require('./drop-menu/drop-menu');
require('./drop-menu/info-block');
require('./drop-menu/project-menu');
require('./drop-menu/menu-closer');
require('./common/text-wrap-creator');
require('./modal-menu/modal-menu');

require('./production-lines/stages-item');

require('./accordion/initializator');
require('./accordion/switchable-accordion');

require('./field/phone');
require('./field/page-number');
// require('./field/range.js'); // Раскоментить при необходимости
require('../components/smart-tabs/smart-tabs');

require('./sliders/sliders');
require('./sliders/catalog-image-slider-new');

require('./catalog/page-flip/page-flip-creator');
require('./catalog/url-changer');
require('./catalog/contents-menu');

require('fslightbox');

require('./open-text/open-text');
require('./scroll-line/scroll-text');
