/**
 * оно показывает менюшку на ховер и скрывает ее когда мышь убираешь
 */
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  const header = document.querySelector('[data-header]');
  if (header) {
    // ссылочка проекты
    const projectLink = header.querySelector('[data-project-link]');
    // вся менюшка, которая появляется
    const projectMenu = header.querySelector('[data-project-menu]');

    const projectMenuHeight = projectMenu.querySelector('[data-project-menu-height]').clientHeight;

    let projectMenuHover = false;


    if (projectMenu && projectLink) {
      projectLink.addEventListener('mouseover', () => {
        projectMenuHover = false;
        projectMenu.style.height = `${projectMenuHeight.toString()}px`;
        projectMenu.classList.add('_opened');
      });

      projectMenu.addEventListener('mouseenter', () => {
        projectMenu.style.height = `${projectMenuHeight.toString()}px`;
        projectMenuHover = true;
        projectMenu.classList.add('_opened');
      });

      projectLink.addEventListener('mouseleave', () => {
        projectMenu.style.height = '0';
        projectMenu.classList.remove('_opened');
        if (!projectMenuHover) {
          projectMenu.classList.add('_closed');
          projectMenu.classList.remove('_closed');
        }
      });

      projectMenu.addEventListener('mouseleave', () => {
        projectMenu.style.height = '0';
        projectMenu.classList.remove('_opened');
      });
    }
  }
});