import {
  Controller, EffectCreative, Navigation, Pagination, Swiper,
} from 'swiper';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  const containers = document.querySelectorAll('[data-project-slider-container]');

  containers.forEach((container) => {
    if (container) {
      const imageSliderEl = container.querySelector('[data-project-image-slider]');
      const counterEl = container.querySelector('[data-project-slider-counter]');

      let imageSlider;
      let counterSlider;

      const leftArrow = container.querySelector('[data-project-left]');
      const rightArrow = container.querySelector('[data-project-right]');
      const progress = container.querySelector('[data-project-slider-progress]');

      if (imageSliderEl) {
        imageSlider = new Swiper(imageSliderEl, {
          modules: [Controller, Navigation, Pagination, EffectCreative],
          slidesPerView: 1,
          resizeObserver: false,
          speed: 1500,
          preventInteractionOnTransition: true,
          navigation: {
            prevEl: leftArrow,
            nextEl: rightArrow,
          },
          effect: 'creative',
          creativeEffect: {
            prev: {
              translate: ['-50%', 0, -1],
            },
            current: {
              translate: [0, 0, -1],
            },
            next: {
              translate: ['100%', 0, 1],
            },
          },
          pagination: {
            el: progress,
            type: 'progressbar',
          },
        });
      }

      if (counterEl) {
        counterSlider = new Swiper(counterEl, {
          modules: [EffectCreative],
          speed: 1500,
          slidesPerView: 1,
          direction: 'vertical',
          preventInteractionOnTransition: true,
        });
      }

      imageSlider.on('slideChange', () => {
        counterSlider.slideTo(imageSlider.realIndex, 1500);
      });
    }
  });
});