import {
  Controller, EffectCreative, Navigation, Pagination, Swiper,
} from 'swiper';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  const containers = document.querySelectorAll('[data-project-app-slider-container]');

  containers.forEach((container) => {
    if (container) {
      const imageSliderEl = container.querySelector('[data-project-app-image-slider]');

      let imageSlider;

      const leftArrow = container.querySelector('[data-project-app-left]');
      const rightArrow = container.querySelector('[data-project-app-right]');
      const progress = container.querySelector('[data-project-app-slider-progress]');

      if (imageSliderEl) {
        imageSlider = new Swiper(imageSliderEl, {
          modules: [Controller, Navigation, Pagination],
          slidesPerView: 'auto',
          speed: 1500,
          preventInteractionOnTransition: true,
          navigation: {
            prevEl: leftArrow,
            nextEl: rightArrow,
          },
          pagination: {
            el: progress,
            type: 'progressbar',
          },
        });
      }
    }
  });
});