import { scrollToTarget } from '../../components/scroll/scroll';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  if (window.innerWidth < 1199) {
    return;
  }

  const target = document.querySelector('[data-scroll-to]');

  if (target) {
    const offset = parseInt(target.dataset.offset) || 0;
    scrollToTarget(target, offset);
  }
});