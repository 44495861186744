import {onDomReady} from "../../components/dynamic/observer";
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

 class NewImageSlider {
     constructor(container) {
         this.container = container;
         this.lightbox();
     }

     lightbox() {
         const photo = new PhotoSwipeLightbox({
             gallery: this.container,
             children: 'a',
             pswpModule: () => import('photoswipe'),
         });
         photo.init();
     }
 }

onDomReady(() => {
    const container = document.querySelector('[data-zoom-container]');
    if (container) {
        new NewImageSlider(container);
    }
});