require('./index-slider');
require('./project-slider');
require('./app-project-slider');
require('./about-slider');
require('./employe-slider');
require('./article-slider');
require('./stages-slider');
require('./images-slider');
require('./series-images-slider');
require('./experience-slider');
require('./hero-slider');
require('./rich-slider');
require('./production-slider');
require('./catalog-image-slider');
require('./another-series');
