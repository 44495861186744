import {
  FreeMode, Navigation, Pagination, Swiper,
} from 'swiper';
import { onDomReady } from '../../components/dynamic/observer';

class ImagesSlider {
  constructor(container) {
    this.container = container;

    this.sliderElement = this.container.querySelector('[data-another-slider]');

    const rootStyles = getComputedStyle(document.documentElement);
    this.largeMinWidth = rootStyles.getPropertyValue('--large-min-width') || 1200;

    this.drag = false;

    this.init();
    this.update();
  }

  init() {
    this.initSlider();
  }

  update() {
    window.requestAnimationFrame(this.update.bind(this));
  }

  initSlider() {
    const leftArrow = this.container.querySelector('[data-slider-left]');
    const rightArrow = this.container.querySelector('[data-slider-right]');
    this.slider = new Swiper(this.sliderElement, {
      modules: [Pagination, FreeMode, Navigation],
      freeMode: true,
      speed: 1500,
      slidesPerView: 'auto',
      navigation: {
        nextEl: rightArrow,
        prevEl: leftArrow,
      },
      pagination: {
        el: this.progress,
        type: 'progressbar',
      },
      breakpoints: {
        0: {
          spaceBetween: 16,
          // slidesPerView: 2,
        },
        767: {
          spaceBetween: 12,
          // slidesPerView: 3,
        },
        1199: {
          spaceBetween: 20,
          // slidesPerView: 4,
        },
      },
    });
  }
}

onDomReady(() => {
  const containers = document.querySelectorAll('[data-another-slider-container]');
  containers.forEach((container) => {
    if (container) {
      new ImagesSlider(container);
    }
  });
});