import { onDomReady } from '../../components/dynamic/observer';

class ModalMenu {
  constructor(selector) {
    this.menu = selector;
    this.menuType = this.menu.getAttribute('data-modal-menu');
    this.openButtons = document.querySelectorAll(`[data-menu-open="${this.menuType}"]`);
    this.closer = this.menu.querySelector('[data-closer]');

    this.layout = document.querySelector('[data-layout]');
    this.layoutBg = this.layout.querySelector('[data-layout-bg]');

    this.scrollTop = 0;
    this.lastScrollTop = 0;

    this.bind();
    this.eventListeners();
  }

  bind() {
    this.bindOpen();
    this.bindClose();
  }

  bindOpen() {
    window.addEventListener('Employee-item-click', (e) => {
      this.openButtons.forEach((button) => {
        if (button === e.detail.button) {
          console.log(button, e.detail.button)
          document.dispatchEvent(new CustomEvent('Employee-menu-opened', {
            detail: {
              employeeId: button.dataset.employee,
              index: button.dataset.index
            },
          }));

          this.openMenu();
        }
      });
    });

    this.openButtons.forEach((button) => {
      if (!button.dataset.employee) {
        button.addEventListener('click', () => {
          this.openMenu();
        });
      }
    });
  }

  bindClose() {
    this.closer.addEventListener('click', () => {
      this.closeMenu();
    });
  }

  openMenu() {
    this.lastScrollTop = this.scrollTop;
    this.menu.classList.add('_open');

    if (window.innerWidth < 1200) {
      setTimeout(() => {
        this.layout.classList.add('_menu-opened');
        document.body.style.overflow = 'hidden';
        document.body.style.height = '100%';
      }, 1200);
    } else {
      this.layout.classList.add('_menu-opened');
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
    }
  }

  closeMenu() {
    this.menu.classList.remove('_open');
    this.layout.classList.remove('_menu-opened');
    document.body.style.position = '';
    window.scrollTo(0, this.lastScrollTop);
    document.body.style.overflow = '';
    document.body.style.height = '';
  }

  eventListeners() {
    window.addEventListener('scroll', () => {
      this.scrollTop = window.pageYOffset;
    });

    document.addEventListener('click', (e) => {
      const { target } = e;
      const notModalContent = target === this.layoutBg;
      if (notModalContent) {
        this.closeMenu();
      }
    });

    document.addEventListener('contentsMenuSelectedPage', () => {
      this.closeMenu();
    });
  }
}

onDomReady(() => {
  document.querySelectorAll('[data-modal-menu]').forEach((menu) => {
    new ModalMenu(menu);
  });
})