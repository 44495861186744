import {
  Controller, EffectCreative, FreeMode, Navigation, Pagination, Swiper, Thumbs,
} from 'swiper';
import { onDomReady } from '../../components/dynamic/observer';

class ExperienceSlider {
  constructor(container) {
    this.container = container;

    this.sliderElement = this.container.querySelector('[data-experience-image-slider]');
    this.counterElement = this.container.querySelector('[data-experience-slider-counter]');

    this.leftArrow = container.querySelector('[data-experience-left]');
    this.rightArrow = container.querySelector('[data-experience-right]');
    this.progress = container.querySelector('[data-experience-slider-progress]');

    this.counterItems = [];

    if (this.sliderElement) {
      this.counterItems = this.counterElement.querySelectorAll('.swiper-slide');
    }

    this.startXPosition = 0;

    const rootStyles = getComputedStyle(document.documentElement);
    this.largeMinWidth = rootStyles.getPropertyValue('--large-min-width') || 1200;

    this.drag = false;

    this.init();
    this.update();
  }

  init() {
    this.initSlider();
  }

  update() {
    window.requestAnimationFrame(this.update.bind(this));
  }

  initSlider() {
    this.counterSlider = new Swiper(this.counterElement, {
      resizeObserver: false,
      modules: [EffectCreative, FreeMode],
      speed: 1500,
      freeMode: true,
      preventInteractionOnTransition: false,
      watchSlidesProgress: true,
      breakpoints: {
        1: {
          slidesPerView: 3,
          direction: 'vertical',
          centeredSlides: true,
        },
        1199: {
          slidesPerView: 'auto',
          direction: 'horizontal',
        },
      },
    });

    let counterSliderObjet = null;
    if (document.documentElement.clientWidth > 1200) {
      counterSliderObjet = this.counterSlider;
    } else {
      counterSliderObjet = null;
    }

    this.slider = new Swiper(this.sliderElement, {
      modules: [Controller, Navigation, Pagination, EffectCreative, Thumbs, FreeMode],
      resizeObserver: true,
      freeMode: true,
      speed: 1500,
      preventInteractionOnTransition: false,
      slidesPerView: 'auto',
      thumbs: {
        swiper: counterSliderObjet,
      },
      breakpoints: {
        1: {
          spaceBetween: 14,
        },
        767: {
          spaceBetween: 24,
        },
        1199: {
          spaceBetween: 40,
        },
      },
      navigation: {
        prevEl: this.leftArrow,
        nextEl: this.rightArrow,
      },
      pagination: {
        el: this.progress,
        type: 'progressbar',
        clickable: false,
      },
    });

    this.slider.on('slideChange', () => {
      this.counterSlider.slideTo(this.slider.realIndex, 1500);

      if (window.innerWidth > 1199) {
        this.counterItems.forEach((el) => {
          el.classList.remove('_active');
        });

        this.counterItems.item(this.slider.realIndex).classList.add('_active');
      }
    });
  }
}

onDomReady(() => {
  const containers = document.querySelectorAll('[data-experience-slider-container]');

  containers.forEach((container) => {
    if (container) {
      new ExperienceSlider(container);
    }
  });
});