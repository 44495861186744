import {
  FreeMode, Navigation, Pagination, Swiper, Thumbs,
} from 'swiper';
import { onDomReady } from '../../components/dynamic/observer';

class ImagesSlider {
  constructor(container) {
    this.container = container;

    this.sliderElement = this.container.querySelector('[data-series-image-slider]');
    this.sliderElementPre = this.container.querySelector('[data-series-image-slider-pre]');

    this.startXPosition = 0;

    const rootStyles = getComputedStyle(document.documentElement);
    this.largeMinWidth = rootStyles.getPropertyValue('--large-min-width') || 1200;

    this.drag = false;

    this.init();
    this.update();
  }

  init() {
    this.initSlider();
    this.bindDrag();
  }

  update() {
    window.requestAnimationFrame(this.update.bind(this));
  }

  initSlider() {
    const leftArrow = this.container.querySelector('[data-images-slider-left]');
    const rightArrow = this.container.querySelector('[data-images-slider-right]');
    const sliderPre = new Swiper(this.sliderElementPre, {
      modules: [FreeMode],
      freeMode: true,
      watchSlidesProgress: true,
      slidesPerView: 4,
      speed: 1500,
      spaceBetween: 10,
    });
    this.slider = new Swiper(this.sliderElement, {
      modules: [Pagination, FreeMode, Navigation, Thumbs],
      freeMode: true,
      slidesPerView: 'auto',
      speed: 1500,
      spaceBetween: 10,
      navigation: {
        nextEl: rightArrow,
        prevEl: leftArrow,
      },
      thumbs: {
        swiper: sliderPre,
      },
      pagination: {
        el: '.series-image-slider__pagination',
        clickable: false,
      },
    });
  }

  sendCursorScaleEvent(scaleValueBoolValue) {
    const event = new CustomEvent('cursorScale', {
      detail: {
        scale: scaleValueBoolValue,
      },
    });
    window.dispatchEvent(event);
  }

  setStartXPosition(e) {
    if (window.innerWidth >= this.largeMinWidth) {
      this.startXPosition = e.x;
    } else {
      this.startXPosition = e.touches[0].clientX;
    }
  }

  handleTouchStartEvent(e) {
    this.drag = true;
    this.setStartXPosition(e);
    this.sendCursorScaleEvent(true);
  }

  handleTouchEndEvent(e) {
    this.drag = false;
    this.sendCursorScaleEvent(false);
  }

  bindDrag() {
    this.slider.on('touchStart', (s, e) => {
      this.handleTouchStartEvent(e);
    });

    this.slider.on('touchEnd', (s, e) => {
      this.handleTouchEndEvent(e);
    });

    this.slider.on('touchMove', (s, e) => {
      const event = new CustomEvent('cursorMove', {
        detail: {
          offsetX: e.x,
          offsetY: e.y,
        },
      });
      window.dispatchEvent(event);
    });
  }
}

onDomReady(() => {
  const containers = document.querySelectorAll('[data-images-slider-container]');
  containers.forEach((container) => {
    if (container) {
      new ImagesSlider(container);
    }
  });
});