import {
  Controller, EffectCreative, Navigation, Swiper,
} from 'swiper';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  const container = document.querySelector('[data-rich-slider-container]');

  if (container) {
    const textSliderEl = container.querySelector('[data-text-rich-slider]');
    const imageSliderEl = container.querySelector('[data-image-rich-slider]');

    let imageSlider;
    let textSlider;

    const leftArrow = container.querySelector('[data-rich-slider-left]');
    const rightArrow = container.querySelector('[data-rich-slider-right]');

    if (imageSliderEl) {
      imageSlider = new Swiper(imageSliderEl, {
        resizeObserver: false,
        modules: [Controller, Navigation, EffectCreative],
        speed: 1500,
        preventInteractionOnTransition: true,
        slidesPerView: 'auto',
        navigation: {
          prevEl: leftArrow,
          nextEl: rightArrow,
        },
      });
    }

    if (textSliderEl) {
      textSlider = new Swiper(textSliderEl, {
        resizeObserver: false,
        slidesPerView: 'auto',
        preventInteractionOnTransition: true,
      });
    }

    imageSlider.on('slideChange', () => {
      textSlider.slideTo(imageSlider.realIndex, 0);
    });
  }
});