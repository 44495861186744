import LiveEvent from '../../components/live/live';

const header = document.querySelector('[data-header]');
const hamburger = document.querySelector('[data-menu-button]');
const dropMenu = document.querySelector('[data-drop-menu]');

let scrollTop;
let lastScrollTop;
window.addEventListener('scroll', () => {
  scrollTop = window.pageYOffset;
});

new LiveEvent('click', '[data-menu-button]', (e) => {
  e.preventDefault();

  if (!hamburger.classList.contains('_open')) {
    hamburger.classList.add('_open');
    dropMenu.classList.add('_open');
    header.classList.add('_open');
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100%';
    lastScrollTop = scrollTop;
  } else {
    hamburger.classList.remove('_open');
    dropMenu.classList.remove('_open');
    header.classList.remove('_open');
    document.body.style.position = 'relative';
    window.scrollTo(0, lastScrollTop);
    document.body.style.overflow = '';
    document.body.style.height = '';
  }
});