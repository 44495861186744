import {
  Controller, EffectCreative, Navigation, Swiper,
} from 'swiper';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  if (window.innerWidth > 1199) {
    return;
  }

  const container = document.querySelector('[data-stages-slider-container]');

  if (container) {
    const sliderEl = container.querySelector('[data-stages-slider]');
    const textSlider = container.querySelector('[data-stages-text-slider]');
    const leftArrow = container.querySelector('[data-stages-slider-left]');
    const rightArrow = container.querySelector('[data-stages-slider-right]');

    const stagesSlider = new Swiper(sliderEl, {
      modules: [Controller, Navigation, EffectCreative],
      effect: 'creative',
      breakpoints: {
        0: {
          allowTouchMove: true,
        },
        1200: {
          allowTouchMove: false,
        },
      },
      creativeEffect: {
        prev: {
          opacity: 0,
        },
        current: {
          opacity: 1,
        },
        next: {
          opacity: 0,
        },
      },
      navigation: {
        prevEl: leftArrow,
        nextEl: rightArrow,
      },
    });

    const stagesTextSlider = new Swiper(textSlider, {
      modules: [Controller, EffectCreative],
      preventInteractionOnTransition: true,
      effect: 'creative',
      creativeEffect: {
        prev: {
          opacity: 0,
          zIndex: 1,
        },
        current: {
          opacity: 1,
          zIndex: 2,
        },
        next: {
          opacity: 0,
          zIndex: 1,
        },
      },
    });

    stagesSlider.on('slideChange', () => {
      document.dispatchEvent(new CustomEvent('changeSlide', { detail: stagesSlider.activeIndex }));

      stagesTextSlider.slideTo(stagesSlider.activeIndex);
    });

    stagesTextSlider.on('slideChange', () => {
      document.dispatchEvent(new CustomEvent('changeSlide', { detail: stagesTextSlider.activeIndex }));

      stagesSlider.slideTo(stagesTextSlider.activeIndex);
    });
  }
});